
<template>

  <div class="avatar-container">
    <h1>Avatar</h1>

    <AvatarDisplay :avatar="selectedAvatar" :accessory="selectedAccessory" />

    <!-- Champ de sélection du pseudo -->
    <div class="pseudo-container">
      <label for="pseudo">Choisis un pseudo :</label>
      <input type="text" id="pseudo" v-model="pseudo" @input="validatePseudo" maxlength="16"
        placeholder="Pseudo (max 16 caractères)" />
    </div>

    <!-- Boutons de sélection -->
    <div class="toggle-buttons">
      <button @click="toggleView('avatars')" :class="{ active: currentView === 'avatars' }">
        Choisir un avatar
      </button>
      <button @click="toggleView('accessories')" :class="{ active: currentView === 'accessories' }">
        Choisir un accessoire
      </button>
    </div>

    <!-- Grilles dynamiques -->
    <div v-if="currentView === 'avatars'" class="avatar-grid">
      <div v-for="(avatar, index) in avatars" :key="index" class="avatar-cell" :class="`avatar-${index}`"
        @click="selectAvatar(index)"></div>
    </div>

    <div v-if="currentView === 'accessories'" class="accessory-grid">
      <div v-for="(accessory, index) in accessories" :key="index" class="accessory-cell" :class="`accessory-${index}`"
        @click="selectAccessory(index)"></div>
    </div>

    <div class="game-controls">
      <button @click="clickValidate">Valider !</button>
    </div>
  </div>
</template>

<script>
import { startGame } from "@/service/card";
import AvatarDisplay from "@/components/AvatarDisplay.vue";

export default {
  components: {
    AvatarDisplay,
  },
  data() {
    return {
      currentView: "avatars", // Vue actuelle: avatars ou accessoires
      selectedAvatar: 0, // Avatar sélectionné par défaut
      selectedAccessory: 0, // Accessoire sélectionné par défaut
      avatars: Array.from({ length: 9 }), // Tableau pour les 9 avatars
      accessories: Array.from({ length: 9 }), // Tableau pour les 9 accessoires
      pseudo: "", // Pseudo du joueur
    };
  },
  methods: {
    goToNext(){
      this.$router.push({ name: 'next' });
      this.$store.dispatch("stopLoading");
    },
    toggleView(view) {
      this.currentView = view;
    },
    selectAvatar(index) {
      this.selectedAvatar = index;
      console.log(`Avatar ${index} sélectionné`);
    },
    selectAccessory(index) {
      this.selectedAccessory = index;
      console.log(`Accessoire ${index} sélectionné`);
    },
    validatePseudo() {
      // Supprime les caractères non-ASCII
      this.pseudo = this.pseudo.replace(/[^ -~]/g, "");
    },
    async clickValidate() {
      this.$store.dispatch("startLoading");
      await startGame(this.selectedAvatar, this.selectedAccessory, 0, this.pseudo);
      this.goToNext();
    },   
  },
};
</script>

<style scoped>
/* Conteneur global */
.avatar-container {
  text-align: center;
}

/* Champ de pseudo */
.pseudo-container {
  margin: 20px 0;
}

.pseudo-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.pseudo-container input {
  padding: 10px;
  width: 60%;
  max-width: 300px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

/* Grilles */
.avatar-grid,
.accessory-grid {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: repeat(3, 250px);
  gap: 10px;
  justify-content: center;
}

.avatar-cell {
  background-image: url('@/assets/avatars.png');
}

.accessory-cell {
  background-image: url('@/assets/accessories.png');
}

.avatar-cell,
.accessory-cell {
  width: 230px;
  height: 230px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  background-size: 690px 690px;
}

.avatar-cell:hover,
.accessory-cell:hover {
  border: 2px solid #aaa;
}

/* Positions des sprites avatars */
.avatar-0 {
  background-position: 0px 0px;
}

.avatar-1 {
  background-position: -230px 0px;
}

.avatar-2 {
  background-position: -460px 0px;
}

.avatar-3 {
  background-position: 0px -230px;
}

.avatar-4 {
  background-position: -230px -230px;
}

.avatar-5 {
  background-position: -460px -230px;
}

.avatar-6 {
  background-position: 0px -460px;
}

.avatar-7 {
  background-position: -230px -460px;
}

.avatar-8 {
  background-position: -460px -460px;
}

/* Positions des sprites accessoires */
.accessory-0 {
  background-position: 0px 0px;
}

.accessory-1 {
  background-position: -230px 0px;
}

.accessory-2 {
  background-position: -460px 0px;
}

.accessory-3 {
  background-position: 0px -230px;
}

.accessory-4 {
  background-position: -230px -230px;
}

.accessory-5 {
  background-position: -460px -230px;
}

.accessory-6 {
  background-position: 0px -460px;
}

.accessory-7 {
  background-position: -230px -460px;
}

.accessory-8 {
  background-position: -460px -460px;
}

/* Boutons de sélection */
.toggle-buttons {
  margin: 20px 0;
}

.toggle-buttons button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.toggle-buttons button.active {
  background-color: #ddd;
  border-color: #999;
}
</style>
