<template>
  <h1>
    Aléa
  </h1>

  <div class="game-controls">
    <button @click="next">Continuer</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { startRound } from "../service/card";

export default {
  computed: {
    ...mapState({
      card: (state) => state.card, 
    }),
    amount() {
      return this.card?.rounds[this.card.currentRound].amount || 0;
    }
  },
  methods: {
    next() {
      this.$router.push({ name: 'next' });
    },
  },
  async mounted() {
    await startRound(this.amount, this.card?.currentRound+1);
  }
}
</script>

<style lang="scss">
div.game-controls {

  button {
    padding: 0.5em;
    margin: 0.5em;
  }
}
</style>