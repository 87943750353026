<template>
    <div id="app">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
  
  <div v-if="isDebugMode" class="debug-header">
    <button class="debug-button" @click="onDebugCardRound0()">Carte vide</button>
    <button class="debug-button" @click="onDebugCardRound1()">Carte tour 1</button>
    <button class="debug-button" @click="onDebugCardRound5()">Carte tour 5</button>
    <button class="debug-button" @click="onDebugCardRound10()">Carte tour 10</button>
    <button class="debug-button" @click="onCardOff()">Retirer la carte</button>
  </div>

  <router-view />
  </div>
</template>

<script>

import { processData } from './service/card';

export default {
  name: 'App',
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    isLoading() {
      return this.$store.state.loading;
    },
  },
  mounted() {
    this.$router.isReady().then(() => {

    });
    this.startLongPolling();
  },
  methods: {
    goToHome() {
      if (this.$route.name == 'avatar') {
        this.$router.push({ name: 'screensaver'});
      } else {
        this.$router.push({ name: 'avatar'});
      }

    },
    goToAvatar() {
      this.$router.push({ name: 'avatar'});
    },
    goToRound() {
      this.$router.push({ name: 'profile' });
    },
    goToScreenSaver() {
      this.$router.push({ name: 'screensaver' });
    },

    onDebugCardRound5() {
      this.onCardDataReceived(
        "00000E088D8B880400C804002000000023" +
        "0105000000000000000000000000000000" +
        "0200000000000000000000000000000000" +
        "0403E80000000000000000000000000000" +
        "0503840102000000000000000000000000" +
        "0603200102030000000000000000000000" +
        "0802BC0102030900000000000000000000" +
        "090258010203090A000000000000000000" +
        "0A01F4010203090A0B0000000000000000" +
        "0C0190010203090A0B1100000000000000" +
        "0D012C010203090A0B1112000000000000" +
        "0E00C8010203090A0B1112130000000000" +
        "1000000102020102020000000000000000" +
        "1108070000000000000000000000000000" +
        "124D792050736575643000000000000000"
      )
    },

    onDebugCardRound10() {
      this.onCardDataReceived(
        "00000E088D8B880400C804002000000023" +
        "010A000000000000000000000000000000" +
        "0200000000000000000000000000000000" +
        "0403E80000000000000000000000000000" +
        "0503840102000000000000000000000000" +
        "0603200102030000000000000000000000" +
        "0802BC0102030900000000000000000000" +
        "090258010203090A000000000000000000" +
        "0A01F4010203090A0B0000000000000000" +
        "0C0190010203090A0B1100000000000000" +
        "0D012C010203090A0B1112000000000000" +
        "0E00C8010203090A0B1112130000000000" +
        "1000C80102020102020000000000000000" +
        "1108070000000000000000000000000000" +
        "124D792050736575643000000000000000"
      )
    },

    onDebugCardRound1() {
      this.onCardDataReceived(
        "00000E088D8B880400C804002000000023" +
        "0101000000000000000000000000000000" +
        "0200000000000000000000000000000000" +
        "0403E80000000000000000000000000000" +
        "0503840102000000000000000000000000" +
        "0603200102030000000000000000000000" +
        "0802BC0102030900000000000000000000" +
        "090258010203090A000000000000000000" +
        "0A01F4010203090A0B0000000000000000" +
        "0C0190010203090A0B1100000000000000" +
        "0D012C010203090A0B1112000000000000" +
        "0E00C8010203090A0B1112130000000000" +
        "1000000102020102020000000000000000" +
        "1108070000000000000000000000000000" +
        "124D792050736575643000000000000000"
      )
    },    
    
    onDebugCardRound0() {
      this.onCardDataReceived(
        "00000E088D8B880400C804002000000023" +
        "0100000000000000000000000000000000" +
        "0200000000000000000000000000000000" +
        "0403E80000000000000000000000000000" +
        "0503840102000000000000000000000000" +
        "0603200102030000000000000000000000" +
        "0802BC0102030900000000000000000000" +
        "090258010203090A000000000000000000" +
        "0A01F4010203090A0B0000000000000000" +
        "0C0190010203090A0B1100000000000000" +
        "0D012C010203090A0B1112000000000000" +
        "0E00C8010203090A0B1112130000000000" +
        "1000000102020102020000000000000000" +
        "1108070000000000000000000000000000" +
        "12416C61696E0000000000000000000000"
      )
    },
    onCardOff() {
      this.goToScreenSaver();
    },

    onCardDataReceived(rawData) {

      const parsedData = processData(rawData);

      this.$store.commit('setCard', parsedData);

      console.log("onCardDataReceived :", parsedData);

      if( parsedData.currentRound > 0 ){
        this.goToRound();
      }else{
        this.goToAvatar();
      }
    },

    startLongPolling() {
      /* const poll = async () => {

        try {
          const response = await fetch("http://localhost/card_event");
          if (response.ok) {
            const data = await response.json();

            if (data.event === "new card") {
              
              
              this.onCardDataReceived(data.raw);

            } else {
              console.log("Événement reçu :", data);

              this.onCardOff();
            }

          } else {
            console.error("Erreur lors du long polling:", response.status);

          }
          this.$store.dispatch('disableDebugMode');
        } catch (error) {
          console.error("Erreur réseau lors du long polling:", error);
          this.$store.dispatch('enableDebugMode');
        } finally {
          // Relancer le long polling après une courte pause
          poll();
        }
      }
      poll(); */

      this.$store.dispatch('enableDebugMode');
    },
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&display=swap');

body {
  margin: 0px;
  overflow: hidden;
}

div.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.debug-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8d7da;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.debug-button {
  background-color: red;
  color: white;
  padding: 0.5em;
  border: none;
  cursor: pointer;
  margin: 0.5em;
}

div.content {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  max-width: 50em;
}

div.footer {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: smaller;
  color: $light-text-color;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-color;

  h1 {
    margin-top: 150px;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
