import defs from "./definitions";

async function write_data(data){

  console.log( "write_data : ", data );
  try {
    const response = await fetch("http://localhost/write_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bytes: data }),
    });

    const result = await response.json();
    console.log("Response from server:", result);
    return result.status === "ok";
  } catch (error) {
    console.error("Error sending data:", error);
    return false;
  }
}

async function write_cleanRound(amount, roundIndex) {
  console.log("write_cleanRound ", amount, roundIndex);

  const data = [
    addressFromRound(roundIndex), (amount >> 8) & 0xff, amount & 0xff, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  return await write_data(data);
}

async function write_round_meta(roundIndex, languageID) {
  console.log("write_round_meta ", roundIndex, languageID);
  
  const data = [
    defs.metaRoundAddress, roundIndex, languageID, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  return await write_data(data);
}

async function write_game_meta(avatarID, accessoryID) {
  console.log("write_game_meta ", avatarID, accessoryID);
  
  const data = [
    defs.metaGameAddress, avatarID, accessoryID, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  return await write_data(data);
}

async function write_pseudo(pseudo) {
  
  const data = [
    defs.pseudoAddress, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  console.log ("write pseudo :");

  for (let i = 0; i < pseudo.length && i < data.length - 1; i++) {
    data[i + 1] = pseudo.charCodeAt(i);
    console.log( pseudo.charCodeAt(i));
  }

  

  return await write_data(data);
}

   /**
* Calcule le numéro de round à partir de l'adresse.
* @param {number} address - L'adresse à convertir en numéro de round.
* @returns {number} Le numéro de round.
*/
function roundFromAddress(address) {
 return (
   address -
   defs.roundsFirstAddress -
   Math.floor((address - defs.roundsFirstAddress) / 4) +
   1
 );
}

function addressFromRound(round) {
 return (
   defs.roundsFirstAddress +
   round +
   Math.floor((round-1) / 3) -
   1
 );
}

export async function resetGame() {
  return await write_round_meta(0,0);
}

export async function startRound(amount, roundIndex, languageID) {

  let success = await write_cleanRound(amount, roundIndex)

  if (success) {
    success = await write_round_meta(roundIndex, languageID)
  }

  return success;
}

export async function startGame(avatarID, accessoryID, languageID, pseudo) {
  let success = await write_game_meta(avatarID, accessoryID);

  if (success) {
    success = await write_pseudo(pseudo);
  }

  if (success) {
    success = await startRound(defs.game.startAmount, 1 ,languageID);
  }
}

 /**
   * Analyse les données brutes pour extraire les informations sur les rounds.
   * @param {string} rawData - Les données brutes sous forme de chaîne hexadécimale.
   * @returns {Object} Un objet contenant le round actuel et les détails des rounds.
   */
export function processData(rawData) {
  const dataLength = rawData.length;
  const blockSize = 34; // Chaque bloc de 17 bytes correspond à 34 caractères en hexadécimal (2 caractères par byte)
  const rounds = {};
  let currentRound = 0;
  let avatarId = -1;
  let accessoryId = -1;
  let languageId = -1;

  let pseudo = "";

  for (let i = 0; i < dataLength; i += blockSize) {
    // Extraire un bloc de 34 caractères (17 bytes) et convertir en valeurs
    const block = rawData.slice(i, i + blockSize);
    const address = parseInt(block.slice(0, 2), 16);

    console.log("Parse " + block);

    if (address === defs.metaRoundAddress) {

      currentRound = parseInt(block.slice(2, 4), 16);
      languageId = parseInt(block.slice(4, 6), 16);

      console.log("->metaRound : ", currentRound, ", ",  languageId);

    } else if (address === defs.metaGameAddress) {

      avatarId = parseInt(block.slice(2, 4), 16);
      accessoryId = parseInt(block.slice(4, 6), 16);

      console.log("->metaGame");

    } else if (address === defs.pseudoAddress) {

      console.log("->pseudo");

      for (let j = 2; j < blockSize; j += 2) {
        const charCode = parseInt(block.slice(j, j + 2), 16);

        if (charCode === 0){
          break;
        }

        pseudo += String.fromCharCode(charCode);
      }

    } else if (address >= defs.roundsFirstAddress) {

      const round = roundFromAddress(address);
      const purchases = [];

      
      console.log("->round ", round);

      if (round <= currentRound) {

        for (let j = 6; j < blockSize; j += 2) {
          const purchaseCode = parseInt(block.slice(j, j + 2), 16);

          if (purchaseCode !== 0) {
            const purchaseInfo = defs.purchaseMap[purchaseCode];
            if (purchaseInfo) {
              purchases.push(purchaseInfo);
            } else {
              purchases.push({ description: "Inconnu", code: purchaseCode });
            }
          }
        }

        rounds[round] = {
          round: round,
          amount: parseInt(block.slice(2, 6), 16),
          purchases: purchases,
        }
      }
    }
  }

  return {
    avatarId: avatarId,
    accessoryId: accessoryId,
    languageId: languageId,
    pseudo: pseudo,
    currentRound: currentRound,
    rounds: rounds,
    raw: rawData,
  };
}
