<template>
  <h1>
    Fin du tour
  </h1>
  <p>Résumé</p>
  <p>Conseils</p>
  <p>Détails</p>
  <h1>Retire la carte pour continuer</h1>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push({ name: 'screensaver' });
    },
  }
}
</script>

<style lang="scss">
div.game-controls {

  button {
    padding: 0.5em;
    margin: 0.5em;
  }
}
</style>