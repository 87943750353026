export default {
  game: {
    startAmount: 1000,
  },
  metaRoundAddress: 0x01,
  metaGameAddress: 0x11,
  pseudoAddress: 0x12,
  roundsFirstAddress: 0x04,
  purchaseMap: {
    0x01: { id: "ipod", description: "Airpods", cost: 100 },
    0x02: { id: "ps5", description: "PS5", cost: 200 },
    0x03: { id: "imac", description: "Imac", cost: 300 },
    0x09: { id: "friends", description: "Sortie entre amis", cost: 100 },
    0x0A: { id: "party", description: "Fête d'anniversaire", cost: 200 },
    0x0B: { id: "travel", description: "Voyage", cost: 300 },
    0x11: { id: "moped", description: "Scooter", cost: 500 },
    0x12: { id: "car", description: "Voiture thermique", cost: 1000, recurringCost: "10%" },
    0x13: { id: "electric-car", description: "Voiture électrique", cost: 1500, recurringCost: "5%" },
    0x31: { id: "apartment", description: "Appartement", cost: 2000, recurringCost: "10%" },
    0x32: { id: "house", description: "Maison", cost: 2400, recurringCost: "15%" },
    0x33: { id: "more-houses", description: "2+ bien", cost: 2000, recurringCost: "10%" },
    0x41: { id: "cfc", description: "CFC", cost: 0 },
    0x42: { id: "maturity", description: "Maturité", cost: 0 },
    0x49: { id: "certificate", description: "Certificat", cost: 200 },
    0x51: { id: "patent", description: "Brevet", cost: 1000 },
    0x52: { id: "degree", description: "Diplôme", cost: 750 },
    0x59: { id: "bachelor", description: "Bachelor", cost: 1000 },
    0x5A: { id: "master", description: "Master", cost: 750 },
  },
  risks: {
    0x01: {id: "fire", condition: 'house', target: 'house', amount: 0, delete: 1, description: "Oh non, votre maison a brulé! Comme vous n'étiez pas assuré, vous l'avez perdue."},
    0x02: {id: "fire-inssurance", condition: 'house', target: null, amount: 0, delete: 0, description: "Oh non, votre maison a brulé! Heureusement que vous étiez assuré!"},
    0x03: {id: "disease", condition: null, target: null, amount: -1000, description: ""},
  },
};
