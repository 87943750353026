<template>
    <div class="selected-avatar">
        <div class="avatar-cell" :class="`avatar-${avatar}`"></div>
        <div v-if="accessory !== null" class="accessory-overlay" :class="`accessory-${accessory}`"></div>
    </div>
</template>

<script>
export default {
    name: "AvatarDisplay",
    props: {
        avatar: {
            type: Number,
            required: true,
        },
        accessory: {
            type: Number,
            default: null,
        },
    },
};
</script>

<style scoped>
.selected-avatar {
    margin: 20px auto;
    position: relative;
    display: inline-block;
    width: 230px;
    height: 230px;
}

.accessory-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 230px;
    height: 230px;
    background-image: url('@/assets/accessories.png');
    background-size: 690px 690px;
    pointer-events: none;
}

.avatar-cell {
    background-image: url('@/assets/avatars.png');
    background-size: 690px 690px;
}

.avatar-cell,
.accessory-cell {
    width: 230px;
    height: 230px;
}

/* Positions des sprites avatars */
.avatar-0 {
  background-position: 0px 0px;
}

.avatar-1 {
  background-position: -230px 0px;
}

.avatar-2 {
  background-position: -460px 0px;
}

.avatar-3 {
  background-position: 0px -230px;
}

.avatar-4 {
  background-position: -230px -230px;
}

.avatar-5 {
  background-position: -460px -230px;
}

.avatar-6 {
  background-position: 0px -460px;
}

.avatar-7 {
  background-position: -230px -460px;
}

.avatar-8 {
  background-position: -460px -460px;
}

/* Positions des sprites accessoires */
.accessory-0 {
  background-position: 0px 0px;
}

.accessory-1 {
  background-position: -230px 0px;
}

.accessory-2 {
  background-position: -460px 0px;
}

.accessory-3 {
  background-position: 0px -230px;
}

.accessory-4 {
  background-position: -230px -230px;
}

.accessory-5 {
  background-position: -460px -230px;
}

.accessory-6 {
  background-position: 0px -460px;
}

.accessory-7 {
  background-position: -230px -460px;
}

.accessory-8 {
  background-position: -460px -460px;
}

</style>