import { createRouter, createWebHistory } from 'vue-router'
import ScreenSaverView from '../views/ScreenSaverView.vue'
import AvatarView from '../views/AvatarView.vue'
import ProfileView from '../views/ProfileView.vue'
import RiskView from '../views/RiskView.vue'
import NextView from '../views/NextView.vue'

const routes = [
  {
    path: '/',
    name: 'screensaver',
    component: ScreenSaverView
  },
  {
    path: '/avatar',
    name: 'avatar',
    component: AvatarView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/next',
    name: 'next',
    component: NextView
  },
  {
    path: '/risk',
    name: 'risk',
    component: RiskView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;