<template>
  <h1>
    Tour #{{ currentRound }} - {{ card.pseudo }}
  </h1>
  <AvatarDisplay :avatar="card.avatarId" :accessory="card.accessoryId" />
  <p>Tu possèdes :</p>
  <div class="purchases">
  <div v-for="purchase in allPurchases" :key="purchase.item.id" class="purchase-item" >
      <div>{{purchase.qty}}</div>
    <div :class="['icon', `icon-${purchase.item.id}`]"
    ></div></div>
    </div>
  <p>Les actions de ce tour</p>
  <div v-for="purchase in lastPurchases" 
    :key="purchase.id" 
    :class="['icon', `icon-${purchase.id}`]">
  </div> 
  <p>Tu as ${{ amount }}.</p>

  <div class="game-controls">
    <button @click="next">Continuer</button>
    <button @click="restart">Recommencer le jeu</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { resetGame } from "@/service/card";
import AvatarDisplay from "@/components/AvatarDisplay.vue";

export default {
  components: {
      AvatarDisplay,
    },
    computed: {
    ...mapState({
      card: (state) => state.card, 
    }),
    
    currentRound() {
      // Exemple pour extraire un tour actuel à partir des données
      return this.card?.currentRound || 0;
    },
    allPurchases() {

      let purchases = [];
      let ids = [];

      if( this.card != null ){
        
        for(let i = 1; i <= this.card.currentRound; i++ ){
          let round = this.card.rounds[i];

          for(const purchase of round.purchases){

            let index = ids.indexOf(purchase.id);

            if( index != -1 ){
              purchases[index].qty++;
            }else{
              purchases.push({
                qty: 1,
                item: purchase
              });
              ids.push( purchase.id );
            }
          }
        }
      }

      return purchases;
    },
    lastPurchases() {
      return this.card?.rounds[this.card.currentRound]?.purchases || 0;
    },  
    amount() {
      return this.card?.rounds[this.card.currentRound]?.amount || 0;
    }
  },
  methods: {
    next() {
      this.$router.push({ name: 'risk' });
    },
    async restart() {
      
      this.$store.dispatch("startLoading");
      await resetGame();
      this.$router.push({ name: 'avatar' });
      this.$store.dispatch("stopLoading");
    },
  }
}
</script>

<style lang="scss">

.icon {
    width: 128px; /* La largeur d'une icône */
    height: 128px; /* La hauteur d'une icône */
    background-image: url('@/assets/goods.png'); /* Chemin vers votre spritesheet */
    background-size: 640px 640px; /* Taille totale de la spritesheet : largeur x hauteur */
    display: inline-block;
  }

  /* Les différentes icônes de la spritesheet */
  .icon.icon-ipod { background-position: 0 0; }
  .icon.icon-ps5 { background-position: -128px 0; }
  .icon.icon-imac { background-position: -256px 0; }
  .icon.icon-friends { background-position : -384px 0; }
  .icon.icon-party { background-position : -512px 0; }
  .icon.icon-travel { background-position : 0 -128px; }
  .icon.icon-moped { background-position : -128px -128px; }
  .icon.icon-car { background-position : -256px -128px; }

.disabled {
    filter: opacity(0.2);
}

div.game-controls {

  button {
    padding: 0.5em;
    margin: 0.5em;
  }
}

.purchases {
  display: flex;
  flex-wrap: wrap; /* Permet de revenir à la ligne si les éléments débordent */
  gap: 1rem; /* Espacement entre les éléments */
  justify-content: center; /* Centre les éléments horizontalement */
}

.purchase-item {
  display: flex;
  flex-direction: column; /* Permet d'empiler la quantité et l'icône */
  align-items: center; /* Centre les éléments verticalement */
  text-align: center; /* Aligne le texte au centre */
}
</style>